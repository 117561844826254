import { useContext, useMemo, useState } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import { Link } from "react-router-dom";
import {
  useCustomQuestionOperations,
  useCustomRegistrationQuestions,
} from "modules/registration";
import { ICustomRegistrationQuestion } from "modules/registration/types";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { QuestionType } from "modules/registration/constants";
import { CompanyFeatures } from "services/splitService/features";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { useNetworkingRecommendationsOperations } from "modules/registration/hooks/useNetworkingRecommendationsOperations";
import { useAppDispatch } from "store/hooks";
import { updateNetworkingRecommendationForEvent } from "modules/event/eventSlice";
import { selectIsNetworkingRecommendationAddon } from "modules/company/redux/selectors";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { EmptyState } from "../EmptyState";
import { QuestionsList } from "../QuestionsList";
import { QuestionFormDialog } from "../QuestionFormDialog";
import { NetworkingRecommendationsSwitch } from "../NetworkingRecommendationsSwitch";

interface Props {
  eventData: IEvent;
  onEventDataChange: (eventData: Partial<IEvent>) => void;
}

export const RegistrationTab = ({ eventData, onEventDataChange }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const dispatch = useAppDispatch();

  const [
    isNetworkingRecommendationsEnabled,
    setIsNetworkingRecommendationsEnabled,
  ] = useState(eventData?.isNetworkingRecommendationsEnabled ?? false);
  const [preloadedQuestion, setPreloadedQuestion] =
    useState<ICustomRegistrationQuestion | null>(null);
  const { addSuccessNotification } = useNotificationActions();
  const { t } = useI18n(["common", "eventForm", "url"]);
  const {
    questions,
    status: fetchStatus,
    refetch: refetchCustomRegistrationQuestions,
  } = useCustomRegistrationQuestions(eventData?.id);

  const { featureEnabled } = useCompanySplitTreatment(
    CompanyFeatures.NETWORKING_RECOMMENDATIONS,
  );
  const isNetworkingRecommendationAddon = useSelector(
    selectIsNetworkingRecommendationAddon,
  );

  const networkingFeature =
    featureEnabled &&
    isNetworkingRecommendationAddon &&
    !eventData.isUnlimitedEvent;

  const questionsList = useMemo(() => {
    if (networkingFeature) {
      return questions;
    }

    return questions.filter((question) => !question.isNetworkingQuestion);
  }, [networkingFeature, questions]);

  const handleFormClose = () => {
    setIsFormVisible(false);
    setPreloadedQuestion(null);
  };

  const {
    reorderQuestions,
    deleteQuestion,
    createQuestion,
    updateQuestion,
    isLoading,
    status,
  } = useCustomQuestionOperations(eventData?.id, {
    onCreateSuccess: (type: QuestionType) => {
      track(Events.CUSTOM_QUESTION_ADDED, {
        eventId: eventData._id,
        eventType: eventData.eventType,
        eventOutcome: eventData.eventOutcome,
        type,
      });
      addSuccessNotification({
        message: t("eventForm:question.successfully.created"),
      });
      handleFormClose();
    },
    onUpdateSuccess: () => {
      addSuccessNotification({
        message: t("eventForm:question.successfully.updated"),
      });
      handleFormClose();
    },
    onDeleteSuccess: () => {
      addSuccessNotification({
        message: t("eventForm:question.successfully.deleted"),
      });
    },
  });

  const onNetworkRecommendationSwitchChange = (
    isNetworkingRecommendationsEnabled: boolean,
  ) => {
    track(Events.NETWORKING_RECOMMENDATIONS_SWITCHED, {
      eventId: eventData._id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
      type: `Network Recommendation switched to ${isNetworkingRecommendationsEnabled}`,
    });
    refetchCustomRegistrationQuestions();
    onEventDataChange({
      isNetworkingRecommendationsEnabled,
    });
    dispatch(
      updateNetworkingRecommendationForEvent({
        id: eventData?.id,
        isNetworkingRecommendationsEnabled,
      }),
    );
  };

  const {
    updateNetworkingRecommendations,
    isUpdateNetworkingRecommendationsLoading,
  } = useNetworkingRecommendationsOperations(
    onNetworkRecommendationSwitchChange,
    eventData?.id,
  );
  const styles = useStyles();

  const handleCreateQuestionClick = () => {
    track(Events.CUSTOM_QUESTION_STARTED, {
      eventId: eventData._id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
    });
    setIsFormVisible(true);
  };

  const handleUpdateQuestionClick = (question: ICustomRegistrationQuestion) => {
    setIsFormVisible(true);
    setPreloadedQuestion(question);
  };

  const handleConfirmation = (checked: boolean) => {
    setIsNetworkingRecommendationsEnabled(checked);
    updateNetworkingRecommendations(checked);
  };

  const showEmptyState =
    (!eventData?.id || !questionsList.length) && fetchStatus !== "pending";

  return (
    <>
      <Box>
        <div>
          <Typography variant="h3">
            {t("eventForm:registration.form")}
          </Typography>
          <div>
            <Typography variant="body1" className={styles.description}>
              {t("eventForm:registration.form.description.1")}{" "}
              <Link
                to={`/event/invitations/${eventData._id ?? ""}`}
                className={styles.link}
              >
                {t("eventForm:guest.list.link")}
              </Link>
            </Typography>
          </div>
          <Typography variant="body1" className={styles.note}>
            {t("eventForm:registration.form.description.2")}
          </Typography>
          <Button<"a">
            variant="text"
            color="dark"
            size="sm"
            className={styles.helpLinkButton}
            href={t("url:custom.questions.help.link")}
            target="_blank"
            rel="noreferrer"
          >
            {t("eventForm:custom.questions.learn.more")}
          </Button>
        </div>
        {showEmptyState && !networkingFeature && (
          <EmptyState
            eventId={eventData?.id}
            onCreateClick={handleCreateQuestionClick}
          />
        )}
        {((Boolean(questionsList.length) && !networkingFeature) ||
          networkingFeature) && (
          <QuestionsList
            questions={questionsList}
            isLoading={isLoading || isUpdateNetworkingRecommendationsLoading}
            status={status}
            reorderQuestions={reorderQuestions}
            deleteQuestion={deleteQuestion}
            onCreateClick={handleCreateQuestionClick}
            onUpdateClick={handleUpdateQuestionClick}
            isNetworkingRecommendationsEnabled={
              isNetworkingRecommendationsEnabled
            }
          />
        )}
        {networkingFeature && (
          <NetworkingRecommendationsSwitch
            handleConfirmation={handleConfirmation}
            isNetworkingRecommendationsEnabled={
              isNetworkingRecommendationsEnabled
            }
            disabled={Boolean(
              // start time of event should be after 100 min of current server time
              eventData?.startTime &&
                (eventData.startTime - Date.now()) / 1000 / 60 < 100,
            )}
          />
        )}
      </Box>
      {isFormVisible && eventData?.id && (
        <QuestionFormDialog
          eventId={eventData?.id}
          isLoading={isLoading}
          preloadedQuestion={preloadedQuestion}
          order={questionsList.length + 1}
          createQuestion={createQuestion}
          updateQuestion={updateQuestion}
          onClose={handleFormClose}
        />
      )}
    </>
  );
};
