import { useI18n } from "i18n";
import logger from "logging/logger";
// eslint-disable-next-line you-dont-need-lodash-underscore/is-string
import { each, isObject, isString, map } from "lodash";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useEventValidator from "modules/event/hooks/useEventValidator";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import useEventInviteActions from "modules/eventInvite/hooks/useEventInviteActions";
import {
  defaultTitle,
  MAX_FLOORS,
  NEW_MAX_FLOORS,
} from "modules/eventForm/constants";
import { getSponsorsInEvent } from "services/apiService/apis";
import useSponsors from "modules/event/hooks/useSponsors";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import templatesService from "services/firestoreService/templates";
import { isTypeOfIMapTemplate } from "modules/event/template";
import getComputedStartEndTimes from "modules/manageEvent/utils/getComputedStartEndTimes";
import { getInitialEventDurationMS } from "modules/manageEvent/utils/getInitialEventDurationMS";
import { useContext } from "react";
import { IEvent, IWelcomeMessage, ISponsor } from "modules/event/types";
import { ITheater, IMapTemplate } from "types/theater";
import { useSelector } from "react-redux";
import {
  selectOnboardDefaultMedia,
  selectOnboardDefaultText,
} from "modules/companySettings/redux/selectors";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { CompanyFeatures } from "services/splitService/features";
import { LayoutChangeDialogContent } from "../../components";
import { IManageEventActions, ManageEventState } from "../types";

interface UseManageEventValues {
  isValidData: (
    eventData: IEvent,
    actions: IManageEventActions,
    prevEventData: IEvent,
    isSave?: boolean,
    // eslint-disable-next-line max-params
  ) => boolean;
  getCloneData: (event: IEvent) => Promise<IEvent>;
  onLayoutSelect: (
    template: {
      code: string;
    },
    theme:
      | {
          image: string;
          backgroundColor: string;
        }
      | string
      | undefined,
    eventData: Partial<IEvent> | null,
    theaterData: ITheater,
    onEventDataChange: (theaters: Record<string, unknown>) => void,
    // eslint-disable-next-line max-params
  ) => void;
  handleAfterSaveData: (
    state: ManageEventState,
    actions: IManageEventActions,
    event: IEvent,
  ) => void;
  getDefaultWelcomeMessage: (title?: string) => IWelcomeMessage;
  loadSponsors: (event: IEvent) => Promise<ISponsor[]>;
  updateTemplateData: (savedEvent: IEvent, currentEvent?: IEvent) => IEvent;
}

const useManageEvent = (): UseManageEventValues => {
  const { featureEnabled: areMaxFloorsIncreased } = useCompanySplitTreatment(
    CompanyFeatures.MAX_FLOORS_INCREASE,
  );
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { isEventWithValidTime } = useEventValidator();
  const {
    getMaxTheaterCapacity,
    getMaxAllowedInSpace,
    eventSettingsTimeLimit,
  } = useCompanyPlanSettings();
  const maxCapacity = getMaxTheaterCapacity();
  const dispatch = useAppDispatch();
  const { sendSpeakerInvite, sendAttendeeInvite } = useEventInviteActions();
  const defaultOnboardMedia = useSelector(selectOnboardDefaultMedia);
  const defaultOnboardText = useSelector(selectOnboardDefaultText);
  const { addSponsor } = useSponsors();
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n(["common", "manageEvent", "errorPage", "server"]);

  const maxFloors = areMaxFloorsIncreased ? NEW_MAX_FLOORS : MAX_FLOORS;

  interface IObjectKeys {
    [key: string]: {};
  }

  // TODO: RC-3275 - Refactor: deepDiff and isValidData functions
  const deepDiff = (a: IEvent, b: IEvent, r: Partial<IEvent>) => {
    each(a, (v, k) => {
      // already checked this or equal...
      if (b) {
        const key = k as keyof IEvent;

        // eslint-disable-next-line no-prototype-builtins
        if (r.hasOwnProperty(k) || b[key] === v) {
          return;
        }
        const prevEventData = b[key];

        // but what if it returns an empty object? still attach?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error RC-3275 this is doing assignment to a read-only object. Ignoring for now, refactor
        r[key] = isObject(v) ? deepDiff(v, prevEventData, r) : [v, b[key]];

        // eslint-disable-next-line consistent-return
        return r[key];
      }
    });
  };

  /**
   * validate data when
   *  - user changes value in field - check prev and current values and find which is changed
   *  - user switches tab from left menu
   *  - user clicks next button
   *  - user saves event
   */
  const isValidData = (
    eventData: IEvent,
    actions: IManageEventActions,
    prevEventData: IEvent,
    isSave = false,
    // eslint-disable-next-line max-params
  ) => {
    const diff: Record<string, IObjectKeys> = {};

    let changedKey = null;

    // If any value is changed, find which key is changed
    if (!isSave) {
      deepDiff(eventData, prevEventData, diff);

      if (Object.keys(diff).length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in diff) {
          // Get key only which has prev and new value
          if (diff[key] && diff[key].length === 2) {
            changedKey = key;

            break;
          }
        }
      }
    }

    actions.setUpgradeMessage("");
    let isValid = true;

    // if start time was changed to a datetime that's later than the current end time set,
    // change the end time to reflect the time limit for this company
    if (eventData.startTime > eventData.endTime) {
      if (changedKey === "startTime") {
        const initialEventDuration = getInitialEventDurationMS(
          eventSettingsTimeLimit,
        );
        const previousDuration =
          prevEventData.endTime - prevEventData.startTime;
        const newDuration =
          eventSettingsTimeLimit === -1
            ? previousDuration
            : Math.min(initialEventDuration, previousDuration);

        actions.setEventData({
          ...eventData,
          endTime: eventData.startTime + newDuration,
        });
      } else {
        addErrorNotification({
          message: t("event:error.start.notify"),
        });
        isValid = false;
      }
    }

    if (isSave && !eventData.name) {
      addErrorNotification({
        message: t("manageEvent:error.message.title"),
      });
      isValid = false;
    }

    // check only on saving event or start/end time change
    if (
      (isSave || changedKey === "startTime" || changedKey === "endTime") &&
      !isEventWithValidTime(eventData, actions)
    ) {
      isValid = false;
    }

    const theater =
      eventData.theaters && eventData.theaters.length
        ? eventData.theaters[0]
        : null;

    // check only on saving event or capacity change
    if (
      theater &&
      (isSave || changedKey === "capacity") &&
      isTypeOfIMapTemplate(theater.template)
    ) {
      // Take capacity from template
      const maxCapacityPerSpace = getMaxAllowedInSpace(theater.template);
      const spacesNeeded = Math.ceil(theater.capacity / maxCapacityPerSpace);

      if (spacesNeeded > maxFloors) {
        addErrorNotification({
          message: t("server:max.allowed.floors.exceed", {
            maxFloors,
            key1: maxCapacityPerSpace,
            key2: maxFloors * maxCapacityPerSpace,
          }),
          position: "tc",
          autoDismiss: 15,
        });

        isValid = false;
      } else if (
        maxCapacity !== -1 &&
        (theater.capacity < 1 || theater.capacity > maxCapacity)
      ) {
        addErrorNotification({
          message: t("eventForm:provide.expected.attendance", {
            key: `${maxCapacity}`,
          }),
          position: "tc",
          autoDismiss: 5,
        });

        isValid = false;
      }
    }

    return isValid;
  };

  const getCloneData = async (event: IEvent) => {
    const theater = event.theaters?.[0];

    if (!theater) {
      throw new Error(t("common:try.again.later"));
    }

    const template = isTypeOfIMapTemplate(theater.template)
      ? theater.template
      : await templatesService.getTemplateByCode(theater.template);

    if (!template) {
      return event;
    }

    const startEndTimes = await getComputedStartEndTimes({
      startTime: 0,
      endTime: 0,
      eventSettingsTimeLimit,
    });

    const cloneEvent: IEvent = {
      ...event,
      id: "",
      _id: "",
      status: "active",
      code: "",
      ...startEndTimes,
      name: `${event.name?.slice(0, 93)} (copy)`, // sliced to keep max length 100 chars
      provider: "",
      createdAt: 0,
      updatedAt: 0,
      clonedFrom: event.id, // Setting this to get some data like sponsors
      company: theater.company, // temp patch, need to check where event is getting company object
      speakers: [],
      theaters: [
        {
          ...theater,
          template,
          spaces: undefined,
          id: "",
          _id: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          code: undefined,
          speakers: [],
        },
      ],
      isNetworkingRecommendationsEnabled: false,
    };

    return cloneEvent;
  };

  const onLayoutSelect = (
    template: {
      code: string;
    },
    theme:
      | {
          image: string;
          backgroundColor: string;
        }
      | string
      | undefined,
    eventData: Partial<IEvent> | null,
    theaterData: ITheater,
    onEventDataChange: (theaters: Record<string, unknown>) => void,
    // eslint-disable-next-line max-params
  ) => {
    // If editing event and user changing the template or theme, show confirmation box
    if (
      eventData?.id &&
      ((theaterData.template as IMapTemplate).code !== template.code ||
        theaterData.theme !== theme)
    ) {
      dispatch(
        addDialogNotification({
          hideCloseButton: true,
          message: "",
          content: <LayoutChangeDialogContent />,
          confirmText: t("manageEvent:confirm.change.floorplan"),
          dismissText: t("manageEvent:dismiss.change.floorplan"),
          onConfirm: () => {
            onEventDataChange({
              theaters: [{ ...theaterData, template, theme }],
            });
          },
        }),
      );
    } else {
      onEventDataChange({ theaters: [{ ...theaterData, template, theme }] });
    }
  };

  const sendSpeakerInvites = async (speakers: Set<string>, event: IEvent) => {
    logger.info(
      `[useManageEvent][sendSpeakerInvites] event: ${event.id}`,
      speakers,
    );

    // update speaker only for new event, for edit 'send emails' button being used
    if (speakers && speakers.size && event?.theaters) {
      const resp = await sendSpeakerInvite(
        event.id,
        event.theaters[0].id,
        Array.from(speakers),
      );

      if (resp.isSuccess) {
        addSuccessNotification({
          message: t("manageEvent:speaker.invites.success"),
          position: "tc",
          autoDismiss: 5,
        });
        track(Events.SPEAKERS_ADDED, {
          eventId: event.id,
          count: speakers.size,
        });
      } else {
        addErrorNotification({
          message: t(resp.message || "manageEvent:error.notify"),
          position: "tc",
          autoDismiss: 5,
        });
      }
    }
  };
  const sendAttendeeInvites = async (
    event: IEvent,
    isEmailInvitation: boolean,
    attendeeEmails?: Set<string>,
  ) => {
    logger.info(
      `[useManageEvent][sendAttendeeInvites] event: ${event.id}`,
      attendeeEmails,
    );

    // update speaker only for new event, for edit 'send emails' button being used
    if (event.theaters && attendeeEmails?.size) {
      try {
        const resp = await sendAttendeeInvite({
          eventId: event.id,
          emails: Array.from(attendeeEmails),
          force: false,
          isEmailInvitation,
        });

        if (resp.isSuccess) {
          if (isEmailInvitation) {
            addSuccessNotification({
              message: t("manageEvent:guest.invite.success"),
              position: "tc",
              autoDismiss: 5,
            });
          }
        } else {
          throw new Error(resp.message);
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "manageEvent:error.notify";
        addErrorNotification({
          message: t(errorMessage),
          position: "tc",
          autoDismiss: 5,
        });
      }
    }
  };
  const addSponsors = (sponsors: ISponsor[], event: IEvent) =>
    Promise.all(map(sponsors, (sponsor) => addSponsor(sponsor, event.id)));

  const handleAfterSaveData = async (
    state: ManageEventState,
    actions: IManageEventActions,
    event: IEvent,
  ) => {
    if (state) {
      await Promise.all([
        sendSpeakerInvites(state.pendingSpeakerInvites, event),
        sendAttendeeInvites(
          event,
          event.sendEmailInvites ?? true,
          state.pendingAttendeeInvites,
        ),
        addSponsors(state.sponsors, event),
      ]).finally(() => {
        actions.resetAfterSaveData();
      });
    }
  };

  const getDefaultWelcomeMessage = (title?: string) => {
    const defaultWelcomeMessage: IWelcomeMessage = {
      title: t(defaultTitle),
      message: defaultOnboardText ? t(defaultOnboardText, { key: title }) : "",
      mediaType: defaultOnboardMedia.mediaType,
      mediaURL: defaultOnboardMedia.url,
      isActive: false,
    };

    return defaultWelcomeMessage;
  };

  // eslint-disable-next-line consistent-return
  const getSponsors = async (eventId: string) => {
    logger.info(`[useSponsors][getSponsors] get sponsor in event: ${eventId}`);

    const resp = await getSponsorsInEvent(eventId);

    if (resp.isSuccess) {
      logger.info(
        `[useSponsors][getSponsors] Successfully got sponsors in event: ${eventId}`,
      );

      return resp.sponsors;
    }
    logger.error(
      `[useSponsors][getSponsors] Could not get sponsors in event: ${eventId} message: ${resp.message}`,
    );

    if (resp.message) {
      addErrorNotification({
        message: resp.message ? t(resp.message) : t("error.unknown"),
      });
    }
  };

  /** Load current sponsors when event data is available */
  const loadSponsors = async (event: IEvent): Promise<ISponsor[]> => {
    const eventId = event.id || event.clonedFrom;

    // Get already added sponsors for this event
    if (eventId) {
      const allSponsors = await getSponsors(eventId);

      if (event.clonedFrom && allSponsors) {
        return map(allSponsors, (data) => ({
          ...data,
          _id: undefined,
          id: undefined,
        }));
      }

      return allSponsors || [];
    }

    return [];
  };

  const updateTemplateData = (savedEvent: IEvent, currentEvent?: IEvent) => {
    if (!savedEvent || !currentEvent) {
      return savedEvent;
    }

    const savedTheater = savedEvent.theaters ? savedEvent.theaters[0] : null;
    const currentTheater = currentEvent?.theaters
      ? currentEvent.theaters[0]
      : null;

    if (
      savedTheater &&
      isString(savedTheater.template) &&
      currentTheater &&
      isTypeOfIMapTemplate(currentTheater.template)
    ) {
      savedTheater.template = currentTheater.template;
    }

    return savedEvent;
  };

  return {
    isValidData,
    getCloneData,
    onLayoutSelect,
    handleAfterSaveData,
    getDefaultWelcomeMessage,
    loadSponsors,
    updateTemplateData,
  };
};

export default useManageEvent;
