import { ref, set, get } from "firebase/database";
import { database, serverValue } from "services/firebaseService/firebaseConfig";
import logger from "logging/logger";

const getRoomWhiteboardDocRef = (roomId: string) =>
  ref(database, `Room/${roomId}/Whiteboard`);

const openWhiteboardFirebase = (
  roomId: string,
  data: { [key: string]: unknown },
): void => {
  logger.info(
    `[openWhiteboard] params: ${JSON.stringify({ roomId, ...data })}`,
  );

  set(getRoomWhiteboardDocRef(roomId), {
    ...data,
    startedAt: serverValue.TIMESTAMP,
  });
};

const addUserToWhiteboardOpen = async (
  roomId: string,
  user: string,
): Promise<void> => {
  const usersOnTableRef = ref(
    database,
    `Room/${roomId}/Whiteboard/whiteboardOpenUsers`,
  );
  const snapshot = await get(usersOnTableRef);
  const currentUsers = snapshot.exists() ? snapshot.val() : [];

  if (!currentUsers.includes(user)) {
    await set(usersOnTableRef, [...currentUsers, user]);
  }
};

const removeUserToWhiteboardOpen = async (
  roomId: string,
  user: string,
): Promise<void> => {
  const usersOnTableRef = ref(
    database,
    `Room/${roomId}/Whiteboard/whiteboardOpenUsers`,
  );
  const snapshot = await get(usersOnTableRef);
  const currentUsers = snapshot.exists() ? snapshot.val() : [];

  if (currentUsers.includes(user)) {
    const updatedUsers = currentUsers.filter(
      (existingUser: string) => existingUser !== user,
    );
    await set(usersOnTableRef, updatedUsers);
  }
};

export default {
  getRoomWhiteboardDocRef,
  openWhiteboardFirebase,
  addUserToWhiteboardOpen,
  removeUserToWhiteboardOpen,
};
