export const styles = {
  dialogContent: {
    position: "relative",
    padding: 0,
    minHeight: 400,
    height: 700,
    overflow: "hidden",
  },
  dialogHeader: { gap: 13, marginBottom: 2 },
  frame: { width: "100%", height: "100%", border: 0 },
};
