import { forwardRef, useCallback, useMemo } from "react";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useSelector } from "react-redux";
import { selectIsRoomWhiteboardOpen } from "modules/roomWhiteboard/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import classNames from "classnames";
import { selectIsVersionLowerThanDeployed } from "modules/version/selectors";
import { useI18n } from "i18n";
import {
  selectCurrentEventId,
  selectIsChatSupportEnabled,
} from "modules/event/selectors";
import { useSmallDesktop } from "modules/common/hooks/useSmallDesktop";
import { HELP_CHAT_ELEMENT_ID, useNeedHelp } from "modules/helpChat";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Fab } from "@remo-co/ui-core/src/components/Fab";
import {
  selectDisableFreshchatWidget as selectDisableFreshchatWidgetGlobal,
  selectEnableAgencyWhiteLabelling as selectEnableAgencyWhiteLabellingGlobal,
  selectIsAgencyWhitelabellingSettingsLoaded,
} from "modules/settings/redux/selectors";
import {
  selectDisableFreshchatWidget as selectDisableFreshchatWidgetCompany,
  selectEnableAgencyWhiteLabelling as selectEnableAgencyWhiteLabellingCompany,
  selectIsCompanySettingsLoaded,
} from "modules/companySettings/redux/selectors";
import { useLocation } from "react-router";
import { HelpChatWrapper } from "../HelpChatWrapper";
import SupportIcon from "./assets/support.svg";
import { styles } from "./styles";

const INTERNAL_PATHNAMES = ["/my-events", "/account-settings", "/event"];

const HelpChat = forwardRef<HTMLDivElement>((_props, ref) => {
  const { t } = useI18n(["common"]);
  const currentEventId = useSelector(selectCurrentEventId);
  const currentTheaterId = useSelector(selectCurrentTheaterId);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const isInEventView = currentEventId && currentTheaterId;
  const isWhiteboardOpen = useSelector(selectIsRoomWhiteboardOpen);
  const { callApi } = useNeedHelp();
  const isSmallDesktop = useSmallDesktop();
  const location = useLocation();
  const isSupportEnabled = useSelector(selectIsChatSupportEnabled);
  const isUpdatePromptVisible = useSelector(selectIsVersionLowerThanDeployed);

  // values below are derived indirectly in-event (via event company ID, event code) or via custom domain
  const isAgencyWhitelabellingSettingsLoaded = useSelector(
    selectIsAgencyWhitelabellingSettingsLoaded,
  );
  const disableFreshchatWidgetGlobal = useSelector(
    selectDisableFreshchatWidgetGlobal,
  );
  const enableAgencyWhiteLabellingGlobal = useSelector(
    selectEnableAgencyWhiteLabellingGlobal,
  );
  // values below are derived from company settings directly
  const isCompanySettingsLoaded = useSelector(selectIsCompanySettingsLoaded);
  const disableFreshchatWidgetCompany = useSelector(
    selectDisableFreshchatWidgetCompany,
  );
  const enableAgencyWhiteLabellingCompany = useSelector(
    selectEnableAgencyWhiteLabellingCompany,
  );

  // on internal routes, use company settings to determine if chat should be hidden
  // otherwise, use the global value that's context-aware
  const hideHelpChat = useMemo(() => {
    if (
      INTERNAL_PATHNAMES.includes(location.pathname) ||
      INTERNAL_PATHNAMES.some((pathname) =>
        location.pathname.startsWith(`${pathname}/`),
      )
    ) {
      // these loaded-checks prevent help chat from showing up before being hidden again
      if (!isCompanySettingsLoaded) {
        return true;
      }

      return enableAgencyWhiteLabellingCompany && disableFreshchatWidgetCompany;
    }

    if (!isAgencyWhitelabellingSettingsLoaded) {
      return true;
    }

    return enableAgencyWhiteLabellingGlobal && disableFreshchatWidgetGlobal;
  }, [
    disableFreshchatWidgetCompany,
    disableFreshchatWidgetGlobal,
    enableAgencyWhiteLabellingCompany,
    enableAgencyWhiteLabellingGlobal,
    isAgencyWhitelabellingSettingsLoaded,
    isCompanySettingsLoaded,
    location.pathname,
  ]);

  const showNewMessage = useCallback(
    () => callApi("showNewMessage"),
    [callApi],
  );

  // use special button for presentation mode
  if (isInBroadcast || (isInEventView && !isSupportEnabled)) {
    return null;
  }

  if (hideHelpChat) {
    return null;
  }

  const className = classNames({
    "ic-in-event": isInEventView,
  });

  const showTooltip = !isUpdatePromptVisible && !isWhiteboardOpen;

  return (
    <HelpChatWrapper>
      <Box
        className={className}
        sx={styles.root}
        data-testid="helpChat"
        ref={ref}
      >
        {showTooltip && (
          <>
            <Box
              sx={{
                ...styles.bg,
                ...(isSmallDesktop && styles.smallDesktopBg),
              }}
            />
            <Box sx={styles.tooltip} data-testid="help-popup">
              <Typography>{t("need.help.click.here")}</Typography>
            </Box>
          </>
        )}
        <Fab
          sx={styles.fab}
          onClick={showNewMessage}
          id={HELP_CHAT_ELEMENT_ID}
          aria-label="Contact us"
          data-testid="contact-button"
        >
          <Box
            component="img"
            src={SupportIcon}
            alt="get-support"
            sx={styles.supportImg}
          />
        </Fab>
      </Box>
    </HelpChatWrapper>
  );
});

export default HelpChat;
