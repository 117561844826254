import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { DialogCloseButton } from "@remo-co/ui-core/src/components/DialogCloseButton";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { getToken } from "services/apiService";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { getDashboardUrl } from "modules/eventAnalytics/utils";
import { styles } from "./styles";

interface Props {
  eventId: string;
  eventName?: string;
  onClose: () => void;
}

export const View = ({ eventId, eventName, onClose }: Props): JSX.Element => {
  const { t } = useI18n(["server", "common", "event"]);
  const { track } = useContext(TRACKING_CONTEXT);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { addErrorNotification } = useNotificationActions();

  useEffect(() => {
    if (!eventId) return;

    track(Events.DATA_DASHBOARD_OPENED, {
      eventId,
    });
  }, [eventId, track]);

  useEffect(() => {
    const asyncGetToken = async () => {
      const userToken = await getToken();
      if (!userToken) {
        addErrorNotification({ message: t("common:error.unknown") });
      }
      setToken(userToken);
      setIsLoading(false);
    };

    asyncGetToken();
  }, [addErrorNotification, t, eventId]);

  const handleDashboardClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const closeDashboard = useCallback(() => {
    track(Events.DATA_DASHBOARD_CLOSED, {
      eventId,
    });
    onClose();
  }, [eventId, track, onClose]);

  return (
    <Dialog
      open
      maxWidth="xl"
      fullWidth
      onClick={handleDashboardClick}
      onClose={closeDashboard}
      data-testid="dashboard-view"
    >
      <DialogTitle>
        <Typography variant="h3">{eventName}</Typography>
        <DialogCloseButton onClick={closeDashboard} />
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {isLoading && (
          <PageLoader message={t("loading")} data-testid="page-loading" />
        )}
        {token && (
          <iframe
            title={eventName}
            style={styles.frame}
            src={getDashboardUrl(token, eventId)}
            data-testid="dashboard-iframe"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
