import { RumInitConfiguration } from "@datadog/browser-rum";
import { getAPIOrigin, getVar } from "config";

const excludedDomainsFromActivityTracking = [
  "datadoghq.com",
  "segment.io",
  "split.io",
];

export const config: RumInitConfiguration = {
  allowedTracingUrls: [getAPIOrigin()],
  applicationId: "bbce78d6-4bf2-4677-9122-ac1742c843a3",
  clientToken: "pub702c4d6867d68f0e52e6b57923a1b292",
  env: getVar("REACT_APP_ENV"),
  enableExperimentalFeatures: ["clickmap"],
  service: "remo",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  silentMultipleInit: false,
  defaultPrivacyLevel: "mask-user-input",
  site: "datadoghq.com",
  telemetrySampleRate: 100,
  traceSampleRate: 100,
  trackLongTasks: true,
  trackResources: true,
  trackSessionAcrossSubdomains: true,
  trackUserInteractions: true,
  useCrossSiteSessionCookie: true,
  useSecureSessionCookie: true,
  version: getVar("REACT_APP_VERSION"),
  beforeSend: (event) => {
    if (
      event.type === "error" &&
      event.error.message.includes("https://www.facebook.com/tr") // facebook tracking pixel csp errors
    ) {
      return false;
    }
    return true;
  },
  excludedActivityUrls: [
    /beacon$/,
    /heartbeat$/,
    /user\/session$/,
    /internal\/version$/,
    "https://firestore.googleapis.com/google.firestore.v1.Firestore/Write/channel",
    (url: string): boolean =>
      excludedDomainsFromActivityTracking.some((domain) =>
        url.includes(domain),
      ),
  ],
};
