import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { useCallback, useContext, useRef } from "react";
import { DialogType } from "modules/dialogNotification/constants";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Actions, trackAction } from "modules/monitoring";
import { selectUserId } from "modules/auth/redux/selectors";
import logger from "logging/logger";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
} from "modules/audioVideo";
import { setActiveTile, setLayout } from "modules/audioVideo/redux/slice";
import {
  DatabaseReference,
  DataSnapshot,
  off,
  onValue,
} from "firebase/database";
import { closeDialogNotificationsByType } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { v4 } from "uuid";
import whiteboardFirebase from "../../whiteboard.firebase";
import {
  openRoomWhiteboard,
  closeRoomWhiteboard,
  updateRoomWhiteboardState,
} from "../../redux/slice";

const useRoomWhiteboardActions = () => {
  const dispatch = useAppDispatch();
  const docRef = useRef<DatabaseReference>();
  const testOpeningRef = useRef<string | null>(null); // Initialize as null or empty string
  const userId = useSelector(selectUserId);

  const { track } = useContext(TRACKING_CONTEXT);

  const openWhiteboard = useCallback(
    ({
      roomId,
      userId,
      userName,
    }: {
      roomId: string | null;
      userId: string | null;
      userName: string;
    }) => {
      const openingId = v4();
      dispatch(openRoomWhiteboard());
      // Use functional update to make sure state is updated correctly
      testOpeningRef.current = openingId;

      if (!roomId) {
        logger.error("[useRoomWhiteboardActions] roomId missing");
        return;
      }

      whiteboardFirebase.openWhiteboardFirebase(roomId, {
        startedByUser: userName,
        startedBy: userId,
        whiteboardOpenUsers: [userId],
        openingId,
      });

      track(Events.WHITE_BOARD_START);
      trackAction(Actions.WHITEBOARD_OPEN, { roomId, userId, userName });
    },
    [dispatch, track],
  );

  const closeWhiteboard = useCallback(
    ({ roomId }: { roomId: string | null }) => {
      if (roomId) {
        dispatch(closeRoomWhiteboard());
        trackAction(Actions.WHITEBOARD_CLOSE, { roomId });
        whiteboardFirebase.removeUserToWhiteboardOpen(roomId, userId ?? "");
      }
    },
    [dispatch, userId],
  );

  const subscribe = useCallback(
    ({
      userId,
      roomId,
      showNotification,
    }: {
      userId: string;
      roomId: string;
      showNotification: boolean;
    }) => {
      logger.info(`[subscribeWhiteboard] subscribing,
    roomId: ${roomId},
    userId: ${userId},
    showNotification: ${JSON.stringify(showNotification)}`);
      docRef.current = whiteboardFirebase.getRoomWhiteboardDocRef(roomId);
      off(docRef.current);
      onValue(docRef.current, (snapshot: DataSnapshot) => {
        const val = snapshot.val();

        if (val) {
          const { openingId, startedBy, startedAt, whiteboardOpenUsers } = val;
          dispatch(
            updateRoomWhiteboardState({
              startedBy,
              startedAt,
            }),
          );

          if (!whiteboardOpenUsers?.length) return;

          if (userId === startedBy) {
            return;
          }
          if (
            !whiteboardOpenUsers.includes(userId) &&
            testOpeningRef.current !== openingId
          ) {
            testOpeningRef.current = openingId;
            whiteboardFirebase.addUserToWhiteboardOpen(roomId, userId);
            dispatch(openRoomWhiteboard());
            dispatch(setLayout(DisplayLayout.Focus));
            dispatch(
              setActiveTile({
                streamId: DisplayItemConfig.whiteboardDisplayId,
                type: DisplayItem.whiteboard,
              }),
            );
          }
        } else {
          dispatch(closeDialogNotificationsByType(DialogType.JOIN_WHITEBOARD));
          closeWhiteboard({ roomId });
          // reset
          dispatch(updateRoomWhiteboardState({}));
        }
      });
    },
    [dispatch, closeWhiteboard],
  );

  const unsubscribe = useCallback(() => {
    if (docRef.current) {
      off(docRef.current);
    }
  }, []);

  return {
    openWhiteboard,
    closeWhiteboard,
    subscribe,
    unsubscribe,
  };
};

export default useRoomWhiteboardActions;
