import { getRemoHosts } from "config";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyPublicSettings } from "modules/company/types";
import { BRAND_NAME } from "../constants";

type AgencyWhiteLabelSettings = Pick<
  CompanyPublicSettings,
  | "enableAgencyWhiteLabelling"
  | "whiteLabelFaviconURL"
  | "whiteLabelLogoURL"
  | "whiteLabelSignInBackgroundURL"
  | "disableFreshchatWidget"
> & {
  // both fields below only filled in IF we're on a custom domain
  customDomainCompany?: string; // will be undefined in most other cases
  brandName?: string;
};

const isRemoDomain = getRemoHosts().includes(window.location.host);

export const initialState: AgencyWhiteLabelSettings & { isLoaded: boolean } = {
  isLoaded: false,
  enableAgencyWhiteLabelling: false,
  brandName: isRemoDomain ? BRAND_NAME : window.location.host,
};

const agencyWhiteLabelSettingsSlice = createSlice({
  name: "agencyWhiteLabelSettings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<AgencyWhiteLabelSettings>) => ({
      ...state,
      ...action.payload,
      brandName: state.brandName,
      isLoaded: true,
    }),
    setIsLoaded: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoaded: action.payload,
    }),
    setBrandName: (state, action: PayloadAction<string>) => ({
      ...state,
      brandName: action.payload,
    }),
  },
});

export const { setSettings, setBrandName, setIsLoaded } =
  agencyWhiteLabelSettingsSlice.actions;

export default agencyWhiteLabelSettingsSlice.reducer;
