import { localhostVars } from "./envVars/localhostVars";
import { developmentVars } from "./envVars/developmentVars";
import { productionVars } from "./envVars/productionVars";
import { OptionalVars, Variables } from "./vars";

export { getAPIHost, getAPIUrl, getAPIOrigin } from "./getApiHost";

export { getRemoHosts } from "./getRemoHost";

export type getVarReturnType<T extends Variables> = T extends OptionalVars
  ? string | undefined
  : string;

/**
 * Get env var from process.env otherwise fallbacks to code in /envVars
 *
 * @param name - The name of the environment variable. Must start with REACT_APP_
 * @returns The value of the environment variable.
 */
export const getVar = <T extends Variables>(name: T): getVarReturnType<T> => {
  if (process.env[name]) return process.env[name] as getVarReturnType<T>;

  switch (process.env.REACT_APP_ENV) {
    case "localhost":
      return localhostVars[name] as getVarReturnType<T>;

    case "development":
      return developmentVars[name] as getVarReturnType<T>;

    case "production":
      return productionVars[name] as getVarReturnType<T>;

    default:
      // checkRequiredEnvironmentVariables() will not let this case occur
      throw new Error(`Invalid REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);
  }
};
