import { Variables } from "../vars";

export const localhostVars: Partial<Record<Variables, string>> = {
  REACT_APP_ANALYTICS_DASHBOARD_URL:
    "https://app.datagol.ai/app/0afc0660-e5ab-47eb-b723-6edc7ef53be0/page/ec3338df-5635-4d0e-bf0b-a76d05c60269",
  REACT_APP_CLIENT_ORIGINS: "http://localhost:3000",
  REACT_APP_API_HOST: "http://localhost:8000",
  REACT_APP_ASSETS_URL:
    "https://storage.googleapis.com/remo-local.appspot.com/",
  REACT_APP_AWS_REGION: "us-east-1",
  REACT_APP_CHARGEBEE_SITE: "remoapp-test",
  REACT_APP_DAILY_API_URL: "https://remo-test.daily.co",
  REACT_APP_DATADOG_CLIENT_TOKEN: "pube1099a2f32a5af9dd1f4d1d204ef53d6",
  REACT_APP_EXIT_PAGE: "https://hi.remo.co/event-exit-page",
  REACT_APP_FIREBASE_API_KEY: "AIzaSyDLKlYEO7nt1wcJX9Brxmj9FO-Zm_AUYTs",
  REACT_APP_FIREBASE_AUTH_DOMAIN: "remo-local.firebaseapp.com",
  REACT_APP_FIREBASE_DB_URL: "https://remo-local.firebaseio.com",
  REACT_APP_FIREBASE_ID: "1085030433977",
  REACT_APP_FIREBASE_PROJECT_ID: "remo-local",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "remo-local.appspot.com",
  REACT_APP_FRESH_CHAT_HOST: "https://wchat.freshchat.com",
  REACT_APP_FRESH_CHAT_TOKEN: "5fb1046b-e419-4ecb-85fb-2da818cdcae3",
  REACT_APP_GCLOUD_PROJECT_NAME: "remo-local",
  REACT_APP_GIPHY_SDK_KEY: "DWNqNX0WWZOLTveCkokGdV7y6wqjrlIW",
  REACT_APP_GRAPHQL_HTTP_ENDPOINT: "http://localhost:8000/api/graphql",
  REACT_APP_GRAPHQL_WS_ENDPOINT: "ws://localhost:8000/api/graphql",
  REACT_APP_HEADWAY_ACCOUNT: "JmOXVx",
  REACT_APP_MIRO_CLIENT_ID: "3074457350118269469",
  REACT_APP_PRICING_PAGE_URL: "http://localhost:3000/pricing.html",
  REACT_APP_REMO_CNAME_HOSTS: "custom-staging.remo.co",
  REACT_APP_REMO_HOST: "localhost:3000",
  REACT_APP_SEGMENT_WRITE_KEY: "0OaBJHwELJV1iBCCrV22qpp2ufqHBN8R",
  REACT_APP_SPLIT_IO_API_KEY: "v6vsu61g40l93hu851e0oak39sb86l5dlamb",
  REACT_APP_USERPILOT_APP_TOKEN: "5ix90o6",
  REACT_APP_VPN_DETECTION_API_KEY: "86ff1879952e42c69419ed0e512fc874",
};
