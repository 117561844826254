import { getVar } from "config";

const getClientOrigins = () => {
  const previewOrigin = getVar("REACT_APP_PREVIEW_HOST");
  if (previewOrigin) {
    return [new URL(previewOrigin)];
  }

  const clientOrigins = getVar("REACT_APP_CLIENT_ORIGINS").split(",");

  return clientOrigins.map((origin) => new URL(origin));
};

export const getRemoHosts = () =>
  getClientOrigins().map((origin) => origin.host);
