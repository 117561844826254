import {
  ExtendedDailyParticipant,
  useParticipantIds,
} from "@daily-co/daily-react";
import { useAppDispatch } from "store/hooks";
import { setPresentationSpeakersSessionIds } from "modules/audioVideo/redux/slice";
import { useCallback, useEffect } from "react";
import { UserData } from "modules/audioVideo/hooks/useUserData/types";
import { useSelector } from "react-redux";
import { selectLiveUsers } from "modules/eventUserPresence/redux/selectors";
import { useActivePresenters } from "../../hooks/usePresenters";

export const isPresenterActive = (participant: ExtendedDailyParticipant) => {
  const { tracks, permissions } = participant;

  if (!tracks) {
    return false;
  }

  const hasActiveTrackState = Object.values(tracks).some(
    (track) => track && track.state !== "off" && track.state !== "blocked",
  );

  return hasActiveTrackState && permissions?.canSend !== false;
};

export const PresentationSpeakersCalculator = () => {
  const dispatch = useAppDispatch();
  const { presenters } = useActivePresenters();
  const onlineUserIds = useSelector(selectLiveUsers);

  const speakers = useParticipantIds({
    filter: useCallback(
      // this function is called on all participants in an event. Keep it optimized.
      (participant: ExtendedDailyParticipant) => {
        if ((participant.userData as UserData)?.conversationId === null)
          return false;

        const isOnline = onlineUserIds.includes(participant.user_id);
        if (!isOnline) return false;

        const isPresenter = presenters.includes(participant.user_id);
        if (!isPresenter) return false;

        const presenterActive = isPresenterActive(participant);

        if (!presenterActive) return false;

        return true;
      },
      [presenters, onlineUserIds],
    ),
    sort: "session_id",
  });

  useEffect(() => {
    dispatch(setPresentationSpeakersSessionIds(speakers));
  }, [dispatch, speakers]);

  return null;
};
