import { useContext } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { OpenInNew } from "@remo-co/ui-core/src/icons/OpenInNew";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { selectIsMobile } from "modules/deviceInfo";
import { EventContentMediaType } from "graphql/generated";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectUserEmail } from "modules/auth/redux/selectors";
import { setLayout } from "modules/audioVideo/redux/slice";
import { DisplayLayout } from "modules/audioVideo";
import { selectCurrentEventId } from "modules/event/selectors";
import useEnterLeaveEvent from "modules/event/hooks/useEnterLeaveEvent";
import {
  closeFullScreenEventContent,
  selectFullScreenEventContent,
} from "../../redux";
import { useStyles } from "./styles";
import { DialogMedia } from "../DialogMedia";
import { isAutojoinURL } from "../../utils";

export const FullScreenContentDialog = () => {
  const { track } = useContext(TRACKING_CONTEXT);
  const userEmail = useSelector(selectUserEmail);
  const currentEventId = useSelector(selectCurrentEventId);
  const { leaveEvent } = useEnterLeaveEvent(currentEventId);
  const dispatch = useAppDispatch();
  const isMobileView = useSelector(selectIsMobile);
  const content = useSelector(selectFullScreenEventContent);
  const { t } = useI18n(["common", "template", "event"]);
  const styles = useStyles();

  const closeDialog = () => {
    dispatch(closeFullScreenEventContent());
    dispatch(setLayout(DisplayLayout.Map));
  };

  const handleCTAClick = () => {
    if (content) {
      track(Events.CONTENT_BANNER_CTA_CLICKED, {
        email: userEmail,
        eventId: currentEventId,
        contentId: content.id,
        url: content.ctaLink,
        name: content.name,
      });

      if (isAutojoinURL(content.ctaLink)) {
        leaveEvent();
      }
    }
  };

  if (!content) {
    return null;
  }

  return (
    <Dialog
      data-testid="popup-dialog"
      aria-labelledby={content.name}
      className={styles.dialog}
      classes={{
        root: styles.dialog,
        container: styles.dialogContainer,
        paper: classNames(styles.dialogPaper, {
          [styles.dialogPaperMobile]: isMobileView,
        }),
      }}
      open
      scroll="paper"
      fullWidth
      hideBackdrop
      disableEnforceFocus
      disablePortal
      disableEscapeKeyDown
    >
      <DialogTitle className={styles.header}>
        <Typography variant="h3" className={styles.title}>
          {content.name}
        </Typography>
        <IconButtonWithTooltip
          id="close-popup-dialog"
          title={t("common:button.close")}
          data-testid="close-button"
          onClick={closeDialog}
          size="small"
          className={styles.closeButton}
        >
          <Close />
        </IconButtonWithTooltip>
        {content.mediaType !== EventContentMediaType.Text &&
          !content.hideOpenInNewTabButton && (
            <Button<"a">
              variant="text"
              color="dark"
              size="sm"
              contentClassName={styles.openInNewTabButtonContent}
              className={styles.openInNewTabButton}
              href={content.media}
              target="_blank"
              rel="noreferrer"
              data-testid="open-new-tab-button"
            >
              <span className={styles.openInNewTabButtonText}>
                {t("event:open.in.new.tab")}
              </span>
              <OpenInNew className={styles.openInNewTabButtonIcon} />
            </Button>
          )}
      </DialogTitle>
      <DialogContent className={styles.content}>
        <DialogMedia content={content} />
      </DialogContent>
      {content.ctaLink && content.ctaText && (
        <DialogActions className={styles.actions}>
          <Button<"a">
            variant="primary"
            color="blue"
            className={styles.ctaButton}
            href={content.ctaLink}
            onClick={handleCTAClick}
            target={!isAutojoinURL(content.ctaLink) ? "_blank" : undefined}
            rel={!isAutojoinURL(content.ctaLink) ? "noreferrer" : undefined}
            size="md"
            data-testid="cta-button"
          >
            {content.ctaText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
