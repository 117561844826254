enum Events {
  ADDED_TO_STAGE_EXECUTED = "Added to Stage - Guest added to Stage",
  ADDED_TO_STAGE_STREAMING_ACCEPTED = "Added to Stage - Accepted to Join Stage",
  ADDED_TO_STAGE_STREAMING_REQUESTED = "Added to Stage - Requested to Join Stage",
  AGENCY_WHITE_LABEL_FAVICON_CHANGED = "Agency White Label Favicon Changed",
  AGENCY_WHITE_LABEL_FAVICON_REMOVED = "Agency White Label Favicon Removed",
  AGENCY_WHITE_LABEL_FRESHCHAT_WIDGET_TOGGLED_OFF = "Agency White Label Freshchat Widget Toggled Off",
  AGENCY_WHITE_LABEL_FRESHCHAT_WIDGET_TOGGLED_ON = "Agency White Label Freshchat Widget Toggled On",
  AGENCY_WHITE_LABEL_LOGO_CHANGED = "Agency White Label Logo Changed",
  AGENCY_WHITE_LABEL_LOGO_REMOVED = "Agency White Label Logo Removed",
  AGENCY_WHITE_LABEL_SIGN_IN_BACKGROUND_CHANGED = "Agency White Label Sign-in Background Changed",
  AGENCY_WHITE_LABEL_SIGN_IN_BACKGROUND_REMOVED = "Agency White Label Sign-in Background Removed",
  AGENCY_WHITE_LABEL_TOGGLED_OFF = "Agency White Label Toggled Off",
  AGENCY_WHITE_LABEL_TOGGLED_ON = "Agency White Label Toggled On",
  AGENDA_ITEM_ADDED = "Agenda Item Added",
  ASPECT_RATIO_DEFAULT = "Aspect Ratio Toggled - Default",
  ASPECT_RATIO_ORIGINAL = "Aspect Ratio Toggled - Original",
  ASSIGN_TABLE_INVITE_ALL = "Assign Tables - Invite All Attendees",
  ASSIGN_TABLE_MOVE_ALL_COMPLETED = "Assign Tables - Move All Attendees Completed",
  ASSIGN_TABLE_MOVE_ALL_STARTED = "Assign Tables - Move All Attendees Started",
  ASSIGN_TABLE_PRESET_CREATED = "Assign Tables - Preset Created",
  ASSIGN_TABLE_PRESET_DELETED = "Assign Tables - Preset Deleted",
  ATTENDEE_ONBOARDING_ABOUT_PROFILE_STEP = "Attendee Onboarding - About Profiles Step",
  ATTENDEE_ONBOARDING_ADD_PROFILE_STEP = "Attendee Onboarding - Add Profile Step",
  ATTENDEE_ONBOARDING_EJECTED = "Attendee Onboarding - Ejected",
  ATTENDEE_ONBOARDING_PROFILE_IMAGE_UPLOADED = "Attendee Onboarding - Profile Image Uploaded",
  AUDIENCE_REACTION_CLICKED = "Audience Reaction Clicked",
  AUDIENCE_VIEW_CLOSED = "Audience View Closed",
  AUDIENCE_VIEW_OPENED = "Audience View Opened",
  AUDIO_REACTIONS_CANCELLED = "Audio Reactions Cancelled",
  AUDIO_REACTIONS_OPENED = "Audio Reactions Opened",
  AUDIO_REACTION_SELECTED = "Audio Reaction Selected",
  BUSINESSCARD_MESSAGE_CLICK = "Clicked Message On BusinessCard",
  CAMERA_START_CLICK = "Camera Start Click",
  CAMERA_START_SUCCESS = "Camera Start Success",
  CAMERA_STOP_SUCCESS = "Camera Stop Success",
  CHANGELOG_HIDE = "Changelog Hide",
  CHANGELOG_INITIALIZATION_COMPLETE = "Changelog Initialization Complete",
  CHANGELOG_READ_MORE = "Changelog Read More",
  CHANGELOG_SHOW = "Changelog Show",
  CHANGELOG_SHOW_DETAILS = "Changelog Show Details",
  CLONE_EVENT_CLICKED = "Clone Event Clicked",
  CONTACT_HOST_CLICKED = "Contact Host Clicked",
  CONTACT_HOST_EMAIL_CLICKED = "Contact Host - Email Clicked",
  CONTACT_HOST_OTHER_CLICKED = "Contact Host - Other Clicked",
  CONTACT_HOST_PHONE_CLICKED = "Contact Host - Phone Clicked",
  CONTENT_BANNER_CLICKED = "Content Banner Clicked",
  CONTENT_BANNER_CREATED = "Content Banner Created",
  CONTENT_BANNER_CTA_CLICKED = "Content Banner CTA Clicked",
  CONTENT_BANNER_DELETED = "Content Banner Deleted",
  CONTENT_BANNER_UPDATED = "Content Banner Updated",
  CUSTOM_DOMAIN_CHECKED = "Custom Domain Checked",
  CUSTOM_DOMAIN_EDIT_REQUESTED = "Custom Domain Edit Requested",
  CUSTOM_DOMAIN_REMOVED = "Custom Domain Removed",
  CUSTOM_DOMAIN_REQUESTED = "Custom Domain Requested",
  CUSTOM_EMAIL_SENDER_CHANGE_REQUESTED = "Custom Email Sender Change Requested",
  CUSTOM_EMAIL_SENDER_REQUESTED = "Custom Email Sender Requested",
  CUSTOM_QUESTION_ADDED = "Custom Question Added",
  NETWORKING_RECOMMENDATIONS_SWITCHED = "Networking Recommendations Switched",
  CUSTOM_QUESTION_ANSWERED = "Custom Question Answered",
  RECOMMENDED_CONNECTION_RATED = "Recommended Connection Rated",
  RECOMMENDED_CONNECTION_LOCATE_PARTICIPANT = "Recommended Connection Locate Participant",
  RECOMMENDED_CONNECTION_INVITE_PARTICIPANT = "Recommended Connection Invite Participant",
  RECOMMENDED_CONNECTION_CONTACTED = "Recommended Connection Contact",
  CUSTOM_QUESTION_SHOWN = "Custom Question Form Shown",
  CUSTOM_QUESTION_STARTED = "Custom Question Started",
  DIRECTORY_CREATED = "Directory Created",
  DATA_DASHBOARD_OPENED = "Data Dashboard Opened",
  DATA_DASHBOARD_CLOSED = "Data Dashboard Closed",
  DIRECTORY_DELETED = "Directory Deleted",
  DIRECTORY_UPDATED = "Directory Updated",
  EMBED_CONTENT_CREATE = "Created Embedded Content",
  EMBED_CONTENT_CREATE_START = "Start Creating Embedded Content",
  EVENT_ACTIVITY_STATUS_CHANGED = "Event Activity Status Changed",
  EVENT_BRANDING_LOGO_CHANGE = "Event Branding Logo Changed",
  EVENT_ENTERED = "Event Entered",
  EVENT_REGISTER_SIGNUP = "Event Register Signup",
  EVENT_REGISTRATION_CREATE_ACCOUNT = "Event Registration Create Account Step",
  EVENT_REGISTRATION_SUCCESS = "Event Registration Success",
  FLOOR_RENAMING_CANCELLED = "Floor Names - Cancelled",
  FLOOR_RENAMING_STARTED = "Floor Names - Started Editing",
  FLOOR_RENAMING_SUCCESS = "Floor Names - Edited Successfully",
  GEARTEST_CAMERA_START = "Turned on Camera for Geartest",
  GEARTEST_MICROPHONE_START = "Turned on Mic for Geartest",
  GET_ATTENDEE_ON_STAGE = "Get Attendee OnStage",
  INTERRUPTED_NETWORK_CONNECTION = "Interrupted WebRTC Network Connection",
  INTERRUPTED_NETWORK_CONNECTION_RECOVERED = "Interrupted WebRTC Network Connection - Recovered",
  JOINED_EVENTS_VIEWED = "Joined Events Viewed",
  JOINED_LOBBY = "Joined Lobby",
  JOIN_EVENT_CLICKED_LANDING_PAGE = "Join Event Clicked - Landing Page",
  JOIN_LOBBY_CLICKED_LANDING_PAGE = "Join Lobby Clicked - Landing Page",
  LINK_EVENTS_CLICKED = "Link Events Clicked",
  LOCK_TABLES_GUESTS_LOCKED_IN = "Lock Tables - Guests Locked In",
  LOCK_TABLES_TABLE_LOCKED = "Lock Tables - Table Locked",
  LOCK_TABLES_TABLE_UNLOCKED = "Lock Tables - Table Unlocked",
  LOGIN_CHAT_SUPPORT_CLICKED = " Log In Chat Support Clicked",
  LOGIN_CLICKED_ANONYMOUS = "Login Anonymously Clicked",
  LOGIN_CLICKED_CUSTOM_SSO = "Login with Custom SSO Clicked",
  LOGIN_CLICKED_EMAIL_PASSWORD = "Login with Email and Password Clicked",
  LOGIN_CLICKED_FACEBOOK = "Login with Facebook Clicked",
  LOGIN_CLICKED_GOOGLE = "Login with Google Clicked",
  LOGIN_CLICKED_LINKEDIN = "Login with Linkedin Clicked",
  LOGIN_CLICKED_MAGIC_LINK = "Login with Magic Link Clicked",
  LOGIN_CLICKED_MICROSOFT = "Login with Microsoft Clicked",
  LOGIN_SUCCESS = "Login Success", // does not tackle all login methods
  LOGIN_SUCCESS_V2 = "User Logged-in Successfully",
  LOGIN_VIEWED = "Login Viewed",
  MAGIC_LINK_BACK_TO_LOGIN_CLICKED = "Magic Link - Back to Login Clicked",
  MAGIC_LINK_CHANGE_EMAIL_CLICKED = "Magic Link - Email Change Clicked",
  MAP_NAVIGATION_USER_LOCATED_SELF = "Map Navigation - Looked up own location",
  MAP_NAVIGATION_ZOOM_IN = "Map Navigation - Zoomed in",
  MAP_NAVIGATION_ZOOM_OUT = "Map Navigation - Zoomed out",
  MICROPHONE_START_CLICK = "Microphone Start Click",
  MICROPHONE_START_SUCCESS = "Microphone Start Success",
  MICROPHONE_STOP_SUCCESS = "Microphone Stop Success",
  MORE_OPTIONS_VIEWED = "More Options Viewed",
  MOVED_FLOORS = "Moved Floors",
  MOVED_TABLES = "Moved Tables",
  MOVE_USER_CANCELLED = "Move User - Cancelled by Host",
  MOVE_USER_CONFIRMED = "Move User - Confirmed",
  MOVE_USER_INTENT = "Move User - Intent",
  MOVE_USER_SUCCESS = "Move User - Successfully Moved",
  NEW_EVENT_BUTTON_CLICKED = "New Event Button Clicked",
  NEW_EVENT_CREATED = "New Event Created",
  NEW_EVENT_VIEWED = "New Event Viewed",
  POLL_ANSWERED = "Poll Answered",
  POLL_CREATED = "Poll Created",
  POLL_DELETED = "Poll Deleted",
  POLL_EDITED = "Poll Edited",
  POLL_ENDED = "Poll Ended",
  POLL_STARTED = "Poll Started",
  PREFERRED_LOGIN_FLOW_SHOWN = "Preferred Login Flow Shown - Landing Page",
  PRESENTATION_START = "Presentation Mode Start",
  PRESENTATION_STOP = "Presentation Mode Stop",
  PRESENT_CONTENT_BUTTON_CLICK = "Present Content Button Clicked",
  PRESENT_CONTENT_SHARED = "Present Content Shared",
  PUBLIC_CHAT_MESSAGE_SENT = "Public Chat Message Sent",
  REACTION_OPENED = "Reactions Opened",
  RELATIONSHIP_STARTED = "Relationship Started",
  REMOVED_FROM_LOBBY = "Removed from Lobby",
  RESTART_EVENT = "Restart Event",
  RTMP_OUT_DESTINATION_ADDED = "RTMP Out destination added",
  RTMP_OUT_DESTINATION_DELETED = "RTMP Out destination deleted",
  RTMP_OUT_STREAM_ENDED_SUCCESSFULLY = "RTMP Out stream ended successfully",
  RTMP_OUT_SUCCESSFULLY_STREAMING = "RTMP Out successfully streaming",
  SCREEN_SHARE_START = "Share Screen Start",
  SHARE_TABLE_LINK = "Share Table Link Clicked",
  SHARE_VIDEO_DELAY_MANY_SEEKS = "Share Video - Too many times video needing sync",
  SHARE_VIDEO_DELAY_SYNC_ACTION = "Share Video - Delay detected, video synchronized",
  SHUFFLE_CLICKED = "Shuffle Attendees Clicked",
  SHUFFLE_COMPLETED = "Shuffle Attendees Completed",
  SHUFFLE_ERROR = "Shuffle Attendees Error",
  SHUFFLE_STARTED = "Shuffle Attendees Started",
  SIGNED_IN_B2B = "Signed In",
  SIGNED_OUT_B2B = "Signed Out",
  SIGNUP_VIEWED = "Signup Viewed",
  SPEAKERS_ADDED = "Speakers Added",
  SPONSORS_ADDED = "Sponsor Added",
  SPONSOR_BANNER_CLICKED = "Sponsor Banner Clicked",
  START_TRIAL_CLICKED = "Start Trial Clicked",
  SUBSCRIPTION_CANCELLED = "Subscription Cancelled",
  SUBSCRIPTION_REACTIVATED = "Subscription Reactivated",
  SYSTEM_CHECK_EJECTED = "System Check Ejected",
  SYSTEM_CHECK_PRIMARY = "System Check - Primary Checks",
  SYSTEM_CHECK_SECONDARY = "System Check - Secondary Checks",
  TABLE_OVERFLOW_DISABLED = "Table Overflow Disabled",
  TABLE_OVERFLOW_ENABLED = "Table Overflow Enabled",
  TICKETING_ACCESS_REQUESTED = "Ticketing - Access Requested",
  TICKETING_ACCOUNT_SETUP = "Ticketing - Account Setup",
  TICKETING_SAVE_TICKET_CLCIKED = "Ticketing - Save Ticket Clicked",
  TICKETING_TICKET_CREATED = "Ticketing - Ticket Created",
  TICKETING_TICKET_CREATION_STARTED = "Ticketing - Ticket Creation Started",
  TICKETING_TICKET_DELETED = "Ticketing - Ticket Deleted",
  TICKETING_TICKET_EDITED = "Ticketing - Ticket Edited",
  TRANSCRIPTION_REQUEST_REGISTERED = "Transcription Requested",
  UPDATE_LIVE_USERS_COUNT_IN_EVENT = "Live Users Count",
  USER_IN_EVENT = "User in event",
  USER_LOGGED_IN_ANONYMOUSLY = "User logged in anonymously",
  USER_ROLE = "User role",
  WHITE_BOARD_START = "Whiteboard Start",
}

export default Events;
